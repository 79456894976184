<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:16
 * @LastEditTime: 2021/10/11 16:35
 * @LastEditors: Ten
 * @Description: 重定向中转页
-->
<script>
export default {
  created() {
    const { params, query } = this.$route
    let { path } = params
    path = typeof path === 'undefined' ? '/' : '/' + path
    this.$router.replace({ path, query })
  },
  render(h) {
    return h()
  }
}
</script>
